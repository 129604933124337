html {
    overflow: scroll;
    overflow-x: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #101427;
}